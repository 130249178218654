import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useInViewport } from "../hooks/useInViewport";

import Grid from "@mui/material/Grid";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import indexes from "../data/indexes.json";
import Aktuelles from "./Aktuelles";
import { Skeleton } from "@mui/material";

const Theme = ({ theme }) => {
  const navigate = useNavigate();

  const barRef = useRef();
  const [barHeight, setBarHeight] = useState(0);

  const setHeight = () => {
    if (theme.logo && barRef.current) setBarHeight(barRef.current.clientHeight);
  };

  const elemRef = useRef();
  const inViewport = useInViewport(elemRef);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barRef.current]);

  useEffect(() => {
    window.addEventListener("resize", setHeight);
    return () => window.removeEventListener("resize", setHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return theme.topics.length > 0 || indexes[theme.hashtags[0]].length > 1 ? (
    <Grid item mobile={12} tablet={6} desktop={4} screen={3}>
      <ImageListItem
        sx={{
          height: "200px !important",
          width: "100%",
        }}
      >
        <figure
          ref={elemRef}
          style={{
            display: "inline-block",
            overflow: "hidden",
            height: theme.logo ? `calc(100% - ${barHeight}px - 1px)` : "100%",
            width: theme.logo ? "calc(100% - 2 * 1px)" : "100%",
            margin: 0,
            cursor: "pointer",
            border: theme.logo ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
            borderBottom: "0px",
          }}
          onClick={() => {
            navigate(`#${theme.hashtags[0]}`);
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={"100%"}
            />
          ) : null}
          {inViewport ? (
            theme.name === "Aktuelles" ? (
              <Aktuelles
                src={theme.image}
                onLoad={() => setLoading(false)}
                className={"themeImageZoom"}
              />
            ) : (
              <img
                className={`themeImageZoom${theme.logo ? "Logo" : ""}`}
                src={theme.image}
                alt={""}
                onLoad={() => setLoading(false)}
                style={{
                  objectFit: theme.logo ? "contain" : "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            )
          ) : null}
        </figure>
        <ImageListItemBar
          position="bottom"
          ref={barRef}
          title={
            <div
              style={{
                fontWeight: 600,
                fontSize: "20px",
                whiteSpace: "initial",
              }}
            >
              {theme.name}
            </div>
          }
        />
      </ImageListItem>
    </Grid>
  ) : null;
};

export default Theme;
