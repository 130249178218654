import React from "react";

import { useSelector } from "react-redux";

import Subtopic from "./Subtopic";
import Theme from "./Theme";

import Grid from "@mui/material/Grid";
import DocumentTitle from "react-document-title";

const getTopic = (themes, indexArray) => {
  var topic = themes;
  indexArray.forEach((index) => {
    topic = topic.topics ? topic.topics[index] : topic[index];
  });
  return topic;
};

const SubtopicsGrid = () => {
  const themes = useSelector((state) => state.theme.themes);
  const activeSubject = useSelector((state) => state.theme.subject);

  const subtopic = getTopic(themes, activeSubject);

  return (
    <DocumentTitle title={`${subtopic.name} | GEOPortal Kreis Herford`}>
      {subtopic && subtopic.topics && subtopic.topics.length > 0 ? (
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: {
              mobile: "flex-start", //"center",
              desktop: "flex-start",
            },
          }}
        >
          {subtopic.topics.map((topic, index) => {
            return topic.topics ? (
              <Theme theme={topic} key={`theme-${index}`} />
            ) : (
              <Subtopic key={`topic-${index}`} topic={topic} />
            );
          })}
        </Grid>
      ) : (
        <div>
          aktuell sind keine Anwendungen zum Thema "{subtopic.name}" vorhanden
        </div>
      )}
    </DocumentTitle>
  );
};

export default SubtopicsGrid;
