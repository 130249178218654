import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { toggle } from "../actions/searchActions";

import AdressSearch from "./AdressSearch";
import FlurSearch from "./FlurSearch";
import DialogTitle from "./DialogTitle";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// import { ReactComponent as LocationIcon } from "../media/location_on_white_24dp.svg";
// import { Menu, MenuItem } from "@mui/material";

const Location = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.search.open);
  const component = useSelector((state) => state.search.component);

  // const [anchorEl, setAnchorEl] = useState(null);
  // const menu = Boolean(anchorEl);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const onClick = (component) => {
  //   handleClose();
  //   dispatch(setComponent(component));
  //   dispatch(toggle());
  // };

  return (
    <div>
      {/* <LocationIcon
        onClick={(e) => setAnchorEl(e.currentTarget)}
        style={{ cursor: "pointer" }}
      />
      <Menu
        anchorEl={anchorEl}
        open={menu}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          sx: {
            borderRadius: "2px",
            marginTop: "10px",
          },
        }}
        MenuListProps={{ sx: { padding: 0 } }}
      >
        <MenuItem onClick={() => onClick("Adresssuche")}>Adresssuche</MenuItem>
        <MenuItem onClick={() => onClick("Flurstückssuche")}>
          Flurstückssuche
        </MenuItem>
      </Menu> */}
      <Dialog
        open={open}
        onClose={() => dispatch(toggle())}
        maxWidth={"sm"}
        PaperProps={{ sx: { borderRadius: 0 } }}
        // fullWidth={true}
      >
        <DialogTitle onClose={() => dispatch(toggle())}>
          {component}
        </DialogTitle>
        <DialogContent>
          {component === "Adresse" ? <AdressSearch /> : <FlurSearch />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Location;
