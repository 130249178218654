import React from "react";

const Impressum = (props) => {
  return (
    <div>
      <h2 style={{ marginBottom: "10px", fontWeight: "600" }}>Impressum</h2>

      <h4 style={{ marginBottom: "10px", fontWeight: "600" }}>Kontakt</h4>
      <p>
        Kreis Herford
        <br />
        Amt für Kataster und Vermessung
        <br />
        Amtshausstr. 2<br />
        32051 Herford
        <br />
        E-Mail:{" "}
        <a href="mailto:geoportal@kreis-herford.de" className={"appLink"}>
          geoportal@kreis-herford.de
        </a>
        <br />
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "20px",
          fontWeight: "600",
        }}
      >
        Haftungshinweis
      </h4>
      <p style={{ textAlign: "justify" }}>
        Die Inhalte des Geoportals im Kreis Herford stellt das Amt für Kataster
        und Vermessung des Kreises Herford bereit. Für die Inhalte sind die
        jeweilig zuständigen Ämter der Kreisverwaltung sowie die
        kreisangehörigen Kommunen verantwortlich.<br></br>Es wird keine Gewähr
        für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität und
        jederzeitige Verfügbarkeit der bereitgestellten Informationen
        übernommen. Der Kreis Herford haftet nicht für Schäden, die durch die
        Nutzung der bereitgestellten Informationen entstehen. Für etwaige
        Schäden, die beim Aufrufen oder Herunterladen von Daten durch
        Computerviren oder der Installation oder Nutzung von Software verursacht
        werden, wird nicht gehaftet. Der Kreis Herford behält es sich
        ausdrücklich vor, einzelne Webseiten oder das gesamte Angebot ohne
        gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
        Veröffentlichung zeitweise oder endgültig einzustellen.
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "20px",
          fontWeight: "600",
        }}
      >
        Links und Verweise
      </h4>
      <p style={{ textAlign: "justify" }}>
        Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Kreis Herford
        keine Haftung für die Inhalte externer Links. Für den Inhalt der
        verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "20px",
          fontWeight: "600",
        }}
      >
        Urheberrechtsschutz an Geodaten und Karten
      </h4>
      <p style={{ textAlign: "justify" }}>
        Geodaten sind urheber- bzw. leistungsrechtlich geschützt
        (Urheberrechtsgesetz [UrhG] vom 9.9.1965, Gesetz gegen den unlauteren
        Wettbewerb vom 7.6.1909 und das Vermessungs- und Katastergesetz NW,
        jeweils in der zurzeit gültigen Fassung). Bei kartographisch gestalteten
        Daten (§ 2 UrhG Nr. 7) bezieht sich der urheberechtliche Schutz auf die
        Art der Darstellung, besonders hinsichtlich der Farbgebung,
        Generalisierung, Schriftgestaltung und Inhaltsauswahl. Auch Luftbilder
        sind als Lichtbilder geschützt (§ 72 UrhG ). Bei den meisten digital
        verfügbaren Geodaten handelt es sich außerdem um Datenbankinhalte, die
        ebenfalls Schutz nach § 87a ff. UrhG besitzen (Dieser Schutz erstreckt
        sich auch auf Daten, die automatisiert visualisiert werden!) Weitere
        Informationen über das Urheberrechtsgesetz:{" "}
        <a
          href="http://www.gesetze-im-internet.de/urhg/index.html"
          target="_blank"
          rel="noreferrer"
          className={"appLink"}
        >
          bundesrecht.juris.de/bundesrecht/urhg
        </a>
        {". "}
        Die Anfertigung einzelner Vervielfältigungen für den privaten Gebrauch
        ist nach § 53 UrhG zulässig. Für eine weitergehende Nutzung von Geodaten
        und Karten muss dagegen ein einfaches Nutzungsrecht (§ 31 UrhG) beim Amt
        für Kataster und Vermessung des Kreises Herford beantragt werden. Als
        weitergehende Nutzungen gelten vor allem die Publikation, die Weitergabe
        und die gewerbliche Nutzung der geschützten Daten.
      </p>
    </div>
  );
};

export default Impressum;
