import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { ReactComponent as CloseIcon } from "../media/close_black_24dp.svg";

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{ fontWeight: 600, display: "flex" }}
      // disableTypography
      {...other}
    >
      <div style={{ width: "calc(100% - 32px)" }}>{children}</div>
      {onClose ? (
        <IconButton
          sx={{
            float: "right",
            padding: 0,
            marginBottom: "auto",
            fill: "rgba(0, 0, 0, 0.6)",
            "&:hover": {
              fill: "black",
            },
          }}
          onClick={onClose}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
