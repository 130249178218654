import { SEARCH_COMPONENT, TOGGLE_OPEN } from "./types";

export const toggle = () => (dispatch) => {
  dispatch({
    type: TOGGLE_OPEN,
  });
};

export const setComponent = (component) => (dispatch) => {
  dispatch({
    type: SEARCH_COMPONENT,
    payload: component,
  });
};
