import React from "react";

import { useSelector } from "react-redux";

import Theme from "./Theme";

import Grid from "@mui/material/Grid";

const ThemesGrid = () => {
  const themes = useSelector((state) => state.theme.themes);

  return themes && themes.length > 0 ? (
    <Grid
      container
      spacing={3}
      sx={{
        justifyContent: {
          mobile: "flex-start", //"center",
          desktop: "flex-start",
        },
      }}
    >
      {themes.map((theme, index) => (
        <Theme theme={theme} key={`theme-${index}`} />
      ))}
    </Grid>
  ) : (
    "keine Themen vorhanden"
  );
};

export default ThemesGrid;
