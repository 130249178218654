import React, { useState } from "react";

const Aktuelles = (props) => {
  const [day, setDay] = useState("");
  const [weekday, setWeekday] = useState("");

  return (
    <svg
      //   role="img"
      viewBox={`0 0 ${props.mobile ? "100% 100%" : "1200 800.16002"}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      style={{ ...props.style }}
    >
      <image
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        href={props.src}
        x="0"
        y="0"
        onLoad={() => {
          const time = new Date();
          setDay(time.getDate().toString().padStart(2, "0"));
          setWeekday(time.toLocaleString("de-DE", { weekday: "long" }));
          if (props.onLoad) {
            props.onLoad();
          }
        }}
      />
      <text
        x={props.mobile ? "73%" : "816.17822"}
        y={props.mobile ? "77%" : "295.3862"}
        transform={props.mobile ? "rotate(-2)" : "rotate(-2.2346907)"}
        style={{
          fontSize: props.mobile ? "15vw" : "223.253px",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "bold",
          fontStretch: "normal",
          fontFamily: "monospace",
          letterSpacing: props.mobile ? "4vw" : "50px",
          textAnchor: "middle",
          fill: "#333333",
          strokeWidth: 0.872086,
        }}
      >
        {day}
      </text>
      <text
        x={props.mobile ? "71.5%" : "792.83704"}
        y={props.mobile ? "103%" : "368.94272"}
        transform={props.mobile ? "rotate(-2)" : "rotate(-2.1969543)"}
        style={{
          fontSize: props.mobile ? "3.5vw" : "52.8066px",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "bold",
          fontStretch: "normal",
          fontFamily: "monospace",
          textAnchor: "middle",
          fill: "#333333",
          strokeWidth: 0.825103,
        }}
      >
        {weekday}
      </text>
    </svg>
  );
};

export default Aktuelles;
