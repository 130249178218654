import React, { useState } from "react";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { Box, Link } from "@mui/material";

function getCookie(name) {
  name = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(name, value) {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 Jahr
  document.cookie =
    name +
    "=" +
    value +
    "; expires=" +
    d.toUTCString() +
    "; path=/; SameSite=Lax";
}

function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

const Datenschutz = () => {
  const { pushInstruction } = useMatomo();

  const [noteOff] = useState(getCookie("cookie_note_off") === "1");
  const [trackingOn, setTrackingOn] = useState(
    getCookie("tracking_on") === "1"
  );

  const track = () => {
    setCookie("tracking_on", 1);
    setTrackingOn(true);
    pushInstruction("setConsentGiven");
  };

  const removeTracking = () => {
    deleteCookie("tracking_on");
    setTrackingOn(false);
    pushInstruction("forgetConsentGiven");
  };

  return (
    <div>
      <h2 style={{ marginBottom: "10px", fontWeight: "600" }}>Datenschutz</h2>

      <p style={{ textAlign: "justify" }}>
        Als natürliche Person haben Sie ein Recht auf den Schutz Ihrer
        personenbezogenen Daten (zum Beispiel: Name, Anschrift, E-Mail-Adresse).
        Nach dem Grundsatz der Transparenz informieren wir Sie{" "}
        <Link
          sx={{
            color: "inherit",
            textDecoration: "underline",
            "&:hover": { textDecoration: "none" },
          }}
          href="https://www.kreis-herford.de/Datenschutz"
        >
          hier
        </Link>{" "}
        über die allgemeinen Datenschutzregelungen. Indem Sie unsere
        Internetseite besuchen, erklären Sie sich damit einverstanden.
      </p>
      <h4
        style={{
          marginBottom: "10px",
          marginTop: "20px",
          fontWeight: "600",
        }}
      >
        Web-Analyse
      </h4>
      <p style={{ textAlign: "justify" }}>
        Wenn Sie sich auf unserer Internetseite informieren, übermittelt Ihr
        Browser Daten an unseren Server. Die Webseite des Geoportals des Kreises
        Herford verwendet den Webanalysedienst Matomo. Durch Textdateien, die
        auf Ihrem Computer gespeichert werden (sog. „Cookies“), erhalten wir
        Nutzungsinformationen, die uns dabei helfen, das Online-Angebot zu
        verbessern. Dabei gespeicherte Daten (gekürzte Internetprotokolldaten)
        können nicht mit Personen in Verbindung gebracht werden. Sie können das
        Erstellen eines Nutzungsprofils durch Ihre Browsereinstellungen
        verhindern oder dem hier für Ihre Sitzung widersprechen bzw. zustimmen
        {noteOff ? ":" : "."}
      </p>
      {noteOff ? (
        <div
          style={{
            margin: "20px 15px 0 15px",
            backgroundColor: " #f0f0f0",
            padding: "25px",
            boxShadow: "0 5px 15px rgba(0, 0, 0, 0.25)",
          }}
        >
          {trackingOn ? (
            <>
              <p style={{ marginTop: 0 }}>
                <b>
                  Die statistische Erfassung Ihres Besuchs ist eingeschaltet.
                </b>
              </p>
              Sie haben uns Ihr Einverständnis erteilt, um statistische Daten
              zur Nutzung unseres Angebots zu sammeln. Ihre Seitenaufrufe werden
              mithilfe von Cookies erfasst und in anonymisierter Form
              gespeichert.
              <br />
              Falls Sie keine statistische Erfassung mehr wünschen, klicken Sie
              bitte auf die folgende Schaltfläche:
              <br />
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "5px",
                  marginTop: "12px",
                  textAlign: "center",
                  borderRadius: 0,
                  background: "#00568e",
                  color: "white",
                  border: "1px solid #00568e",
                  maxWidth: "max-content",
                  "&:hover": {
                    background: "white",
                    color: "#00568e",
                  },
                }}
                onClick={removeTracking}
              >
                Einverständnis widerrufen
              </Box>
            </>
          ) : (
            <>
              <p style={{ marginTop: 0 }}>
                <b>
                  Die statistische Erfassung Ihres Besuchs ist ausgeschaltet.
                </b>
              </p>
              Um statistische Daten zur Nutzung unseres Angebots zu sammeln,
              bitten wir um Ihr Einverständnis. Erst dann werden Ihre
              Seitenaufrufe mithilfe von Cookies erfasst und in anonymisierter
              Form gespeichert.
              <br />
              Sie können Ihr Einverständnis hier jederzeit widerrufen. Falls Sie
              mit einer statistischen Erfassung einverstanden sind, klicken Sie
              bitte auf die folgende Schaltfläche:
              <br />
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "5px",
                  marginTop: "12px",
                  textAlign: "center",
                  borderRadius: 0,
                  background: "#00568e",
                  color: "white",
                  border: "1px solid #00568e",
                  maxWidth: "max-content",
                  "&:hover": {
                    background: "white",
                    color: "#00568e",
                  },
                }}
                onClick={track}
              >
                Einverstanden
              </Box>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Datenschutz;
