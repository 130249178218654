import { CHANGE_SUBJECT, PROGRESS_START } from "../actions/types";

import moment from "moment";

import themes from "../data/themenStruktur.json";

const initialState = {
  themes: createThemes(themes),
  subject: [],
  progress: true,
};

function createThemes(themes) {
  var news = themes[0].topics;
  news = news.filter(
    (topic) =>
      (topic.date && moment(topic.date) > moment().subtract(6, "weeks")) ||
      (topic.update && moment(topic.update) > moment().subtract(6, "weeks"))
  );
  themes[0].topics = news;
  return themes;
}

// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_SUBJECT:
      if (action.payload !== undefined) {
        state.subject = action.payload;
      } else {
        state.subject = [];
      }
      return {
        ...state,
        subject: [...state.subject],
        progress: false,
      };
    case PROGRESS_START:
      return {
        ...state,
        progress: true,
      };
    default:
      return state;
  }
}
