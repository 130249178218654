import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggle } from "../actions/searchActions";

import axios from "axios";

import { ReactComponent as NewTab } from "../media/open_in_new_white_18dp.svg";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

class FlurSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gemeinde: "",
      loadingGemarkung: false,
      gemarkung: "",
      gemarkungen: [],
      loadingFlure: false,
      flur: "",
      flure: [],
      loadingFlurstuecke: false,
      flurstueck: { flurstueck: "" },
      flurstuecke: [],
      error: false,
    };
    this.gemeindeRef = React.createRef();
    this.gemarkungRef = React.createRef();
    this.flurRef = React.createRef();
    this.flurstueckRef = React.createRef();
  }

  componentDidMount() {
    this.gemeindeRef.current.focus();
  }

  getGemarkung = (gemeinde) => {
    this.setState({ loadingGemarkung: true });
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/flurstueck/gemarkung.php?gemeinde=${gemeinde}`
      )
      .then((res) => {
        var gemarkungen = res.data.map((gem) => gem.gemarkung);
        this.setState({ gemarkungen: gemarkungen, loadingGemarkung: false });
        this.gemarkungRef.current.focus();
      })
      .catch((err) => {
        this.setState({ error: true, loadingGemarkung: false });
      });
  };

  getFlur = (gemeinde, gemarkung) => {
    this.setState({ loadingFlure: true });
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/flurstueck/flur.php?gemeinde=${gemeinde}&gemarkung=${
          gemarkung.split(" (")[0]
        }`
      )
      .then((res) => {
        var flure = res.data.map((flur) => flur.flur);
        this.setState({ flure: flure, loadingFlure: false });
        this.flurRef.current.focus();
      });
  };

  getFlurstueck = (gemeinde, gemarkung, flur) => {
    this.setState({ loadingFlurstuecke: true });
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/flurstueck/flurstueck.php?gemeinde=${gemeinde}&gemarkung=${
          gemarkung.split(" (")[0]
        }&flur=${flur}`
      )
      .then((res) => {
        this.setState({ flurstuecke: res.data, loadingFlurstuecke: false });
        this.flurstueckRef.current.focus();
      });
  };

  render() {
    return (
      <div>
        <div
          style={{
            color: "red",
            marginBottom: "20px",
            maxWidth: 300,
            width: "100%",
          }}
        >
          {this.state.error
            ? "Es ist zu einem Fehler bei der Flurstückssuche gekommen. Versuchen Sie es bitte zu einem späteren Zeitpunkt erneut."
            : ""}
        </div>
        <Autocomplete
          disabled={this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          noOptionsText={"keine Übereinstimmung"}
          getOptionLabel={(option) => option}
          options={[
            "Bünde",
            "Enger",
            "Herford",
            "Hiddenhausen",
            "Kirchlengern",
            "Löhne",
            "Rödinghausen",
            "Spenge",
            "Vlotho",
          ]}
          value={this.state.gemeinde}
          onChange={(e, val) => {
            if (val) {
              this.setState({ gemeinde: val }, () =>
                this.getGemarkung(this.state.gemeinde)
              );
            } else {
              this.setState({
                gemeinde: "",
                gemarkung: "",
                gemarkungen: [],
                flur: "",
                flure: [],
                flurstueck: { flurstueck: "" },
                flurstuecke: [],
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={this.gemeindeRef}
              label="Gemeinde"
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
              }}
            />
          )}
        />

        <Autocomplete
          disabled={this.state.gemeinde === "" || this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          getOptionLabel={(option) => option}
          options={this.state.gemarkungen}
          noOptionsText={"keine Übereinstimmung"}
          // groupBy={(option) => option[0]}
          loading={this.state.loadingGemarkung}
          value={this.state.gemarkung}
          onChange={(e, val) => {
            if (val) {
              this.setState({ gemarkung: val }, () =>
                this.getFlur(this.state.gemeinde, this.state.gemarkung)
              );
            } else {
              this.setState({
                gemarkung: "",
                flur: "",
                flure: [],
                flurstueck: { flurstueck: "" },
                flurstuecke: [],
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Gemarkung"
              inputRef={this.gemarkungRef}
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loadingGemarkung ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <Autocomplete
          disabled={this.state.gemarkung === "" || this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          getOptionLabel={(option) => option}
          options={this.state.flure}
          noOptionsText={"keine Übereinstimmung"}
          loading={this.state.loadingFlure}
          value={this.state.flur}
          onChange={(e, val) => {
            if (val) {
              this.setState({ flur: val }, () =>
                this.getFlurstueck(
                  this.state.gemeinde,
                  this.state.gemarkung,
                  this.state.flur
                )
              );
            } else {
              this.setState({
                flur: "",
                flurstueck: { flurstueck: "" },
                flurstuecke: [],
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Flur"
              inputRef={this.flurRef}
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loadingFlure ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <Autocomplete
          disabled={this.state.flur === "" || this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          getOptionLabel={(option) => option.flurstueck}
          options={this.state.flurstuecke}
          noOptionsText={"keine Übereinstimmung"}
          loading={this.state.loadingFlurstuecke}
          value={this.state.flurstueck}
          onChange={(e, val) => {
            if (val) {
              this.setState({ flurstueck: val });
            } else {
              this.setState({
                flurstueck: { flurstueck: "" },
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Flurstück"
              inputRef={this.flurstueckRef}
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loadingFlurstuecke ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <Button
          sx={{ borderRadius: "2px" }}
          fullWidth
          variant="contained"
          disabled={!this.state.flurstueck.ost}
          onClick={() => {
            this.props.toggle();
            // if (window.tracking) {
            //   console.log(
            //     "matomo",
            //     "Startseite",
            //     "Flurstück",
            //     `${this.state.gemarkung} - ${this.state.flur} - ${this.state.flurstueck.flurstueck}`
            //   );
            // }
          }}
          component={Link}
          href={`https://geoportal.kreis-herford.de/geoviewer/?layerIds=51,2,104&visibility=true,true,true&transparency=0,0,0&center=${this.state.flurstueck.ost},${this.state.flurstueck.nord}&marker=${this.state.flurstueck.ost},${this.state.flurstueck.nord}&projection=EPSG:4326&zoomLevel=18`}
          target="_blank"
        >
          <NewTab style={{ marginRight: "5px" }} />
          Flurstück in Karte anzeigen
        </Button>
      </div>
    );
  }
}

FlurSearch.propTypes = {
  toggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  toggle,
})(FlurSearch);
