import React, { useEffect, useState } from "react";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

function getCookie(name) {
  name = name + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(name, value) {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000); // 1 Jahr
  document.cookie =
    name +
    "=" +
    value +
    "; expires=" +
    d.toUTCString() +
    "; path=/; SameSite=Lax";
}

const Cookies = () => {
  const { pushInstruction } = useMatomo();

  const [noteOff, setNoteOff] = useState(getCookie("cookie_note_off"));

  useEffect(() => {
    window.tracking = getCookie("tracking_on") === "1";
  }, []);

  const confirm = () => {
    setCookie("cookie_note_off", 1);
    setCookie("tracking_on", 1);
    setNoteOff(true);
    pushInstruction("setConsentGiven");
  };

  const refuse = () => {
    setCookie("cookie_note_off", 1);
    setNoteOff(true);
    pushInstruction("forgetConsentGiven");
  };

  return navigator.cookieEnabled && !noteOff ? (
    // <div
    //   id="backdrop"
    //   style={{
    //     backgroundColor: "rgba(0, 0, 0, 0.3)",
    //     zIndex: 9999,
    //     position: "fixed",
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //   }}
    // >
    <div
      id="banner"
      style={{
        padding: "20px",
        background: "#00568e",
        color: "white",
        maxWidth: "400px",
        position: "fixed",
        bottom: "23px",
        left: "23px",
        right: "23px",
        zIndex: 9999,
      }}
    >
      Dieser Internetauftritt verwendet Cookies für persönliche Einstellungen
      und besondere Funktionen. Außerdem möchten wir Cookies auch verwenden, um
      statistische Daten zur Nutzung unseres Angebots zu sammeln. Dafür bitten
      wir um Ihr Einverständnis.
      <Box
        sx={{
          margin: "16px 0",
          textDecoration: "underline",
          maxWidth: "max-content",
          "&:hover": {
            textDecoration: "none",
          },
        }}
      >
        <Link
          style={{
            fontWeight: "bold",
            color: "inherit",
            textDecoration: "inherit",
          }}
          to={"/datenschutz"}
        >
          weitere Informationen
        </Link>
      </Box>
      <div style={{ float: "right", display: "flex" }}>
        <Box
          sx={{
            marginRight: "10px",
            cursor: "pointer",
            padding: "5px",
            marginTop: "10px",
            textAlign: "center",
            borderRadius: 0,
            color: "white",
            background: "#00568e",
            border: "1px solid white",
            "&:hover": {
              background: "white",
              color: "#00568e",
            },
          }}
          onClick={refuse}
        >
          Ablehnen
        </Box>
        <Box
          sx={{
            cursor: "pointer",
            padding: "5px",
            marginTop: "10px",
            textAlign: "center",
            borderRadius: 0,
            color: "#00568e",
            background: "white",
            border: "1px solid white",
            "&:hover": {
              color: "white",
              background: "#00568e",
            },
          }}
          onClick={confirm}
        >
          Einverstanden
        </Box>
      </div>
    </div>
  ) : // </div>
  null;
};

export default Cookies;
