import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { ReactComponent as KreisLogo } from "../media/icon_kreishf_signet_w_crop.svg";

import Box from "@mui/material/Box";

const Footer = () => {
  const { trackEvent } = useMatomo();
  const [height, setHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    updateFooterDimensions();
    window.addEventListener("resize", updateFooterDimensions);

    return () => {
      window.removeEventListener("resize", updateFooterDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty("--footerHeight", `${height}px`);
  }, [height]);

  const updateFooterDimensions = () => {
    var newHeight = ref.current.clientHeight + 1;
    if (height !== newHeight) {
      setHeight(newHeight);
    }
  };

  return (
    <footer
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
      }}
      ref={ref}
    >
      <Box
        sx={{
          backgroundColor: "rgba(0,0,0,0.04)",
          display: "flex",
          padding: (theme) => theme.spacing(3),
        }}
      >
        <Box
          sx={{
            paddingRight: (theme) => theme.spacing(3),
            width: "calc(100% - 44px - 1 * 24px)",
          }}
        >
          <Link
            to={"/impressum"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
            className="link"
          >
            Impressum
          </Link>{" "}
          |{" "}
          <Link
            to={"/datenschutz"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
            className="link"
          >
            Datenschutz
          </Link>{" "}
          |{" "}
          <a
            href="mailto:geoportal@kreis-herford.de"
            style={{
              textDecoration: "none",
              color: "black",
            }}
            className="link"
            onClick={() => {
              trackEvent({
                category: "Kontakt",
                action: "Email Link",
                name: "geoportal@kreis-herford.de", // optional
              });
            }}
          >
            Kontakt
          </a>{" "}
          |{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://geoportal.kreis-herford.de/ASWeb/ASC_URM/portallogin.jsp"
            style={{
              textDecoration: "none",
              color: "black",
            }}
            className="link"
          >
            passwortgeschützter Bereich
          </a>
          <div style={{ marginTop: "10px" }}>
            &copy; {new Date().getFullYear()} Kreis Herford - Kataster und
            Vermessung
          </div>
        </Box>
        <Box
          sx={{
            width: "44px",
            alignItems: "center",
            display: "grid",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.kreis-herford.de"
          >
            <KreisLogo style={{ height: "44px", width: "44px" }} />
          </a>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
