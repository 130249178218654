import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { setComponent, toggle } from "../actions/searchActions";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

import { ReactComponent as SearchIcon } from "../media/search_white_24dp.svg";
import { ReactComponent as NewTab } from "../media/open_in_new_black_18dp.svg";
import { ReactComponent as LocationIcon } from "../media/location_on_black_18dp.svg";
import { ReactComponent as ParcelIcon } from "../media/home_black_18dp.svg";
import { ReactComponent as LinkIcon } from "../media/link_black_18dp.svg";
import { ReactComponent as MapIcon } from "../media/map_black_18dp.svg";

import { styled, alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  InputBase,
  Popper,
  Fade,
  List,
  ListItem,
  ListSubheader,
  Link,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";

import searchData from "../data/search.json";
import axios from "axios";
import Location from "./Location";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  background: `linear-gradient(90deg, ${alpha(
    theme.palette.common.white,
    0
  )} 10%, ${alpha(theme.palette.common.white, 0.25)} 20%)`,
  "&:hover": {
    background: `linear-gradient(90deg, ${alpha(
      theme.palette.common.white,
      0
    )} 10%, ${alpha(theme.palette.common.white, 0.35)} 20%)`,
  },
  "& .MuiInputBase-input": {
    padding: 0,
    marginLeft: 0,
    transition: useMediaQuery(theme.breakpoints.up("grid"))
      ? "none"
      : "width 300ms",
    height: "28px",
    width: "0px",
    borderRadius: "2px",
  },
}));

const Search = () => {
  const { trackSiteSearch, trackEvent } = useMatomo();

  const dispatch = useDispatch();
  const theme = useTheme();
  const grid = useMediaQuery(theme.breakpoints.up("grid"));

  const [searchOpen, setSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const [progressAdress, setProgressAdress] = useState(false);
  const [progressFlurstueck, setProgressFlurstueck] = useState(false);
  const [progressBplan, setProgressBplan] = useState(false);
  const [applications, setApplications] = useState(
    searchData.map((d) => {
      return {
        ...d,
        original: d.results,
      };
    })
  );

  const onOpen = () => {
    const grid = window.innerWidth > theme.breakpoints.values.grid;
    if (!grid) {
      setSearchOpen(true);
    } else {
      setOpen(true);
    }
    const scrollbarWidth = `${window.innerWidth - document.body.clientWidth}px`;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = scrollbarWidth;
    document.getElementById("navbar").style.paddingRight = scrollbarWidth;
  };

  const searchCount = () => {
    const count = applications
      .map(
        (app) =>
          app.results.filter(
            (res) => res.title !== "Datenquelle zurzeit nicht erreichbar"
          ).length
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    console.log(count);
    return count;
  };

  const onClose = (log = true) => {
    const grid = window.innerWidth > theme.breakpoints.values.grid;
    if (!grid) {
      setSearchOpen(false);
    }
    if (log && window.tracking && value.length > 0) {
      trackSiteSearch({
        keyword: value,
        category: "Abbruch",
        count: searchCount(),
      });
      trackEvent({
        category: "Suche",
        action: "Abbruch",
        name: value,
      });
    }
    setOpen(false);
    onChangeValue();
    document.body.style = null;
    document.getElementById("navbar").style = null;
  };

  const ref = useRef();

  useEffect(() => {
    if (ref.current && !ref.current.onfocus) {
      ref.current.onfocus = onOpen;
      // ref.current.onblur = onClose;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const prevSearchOpen = useRef();

  useEffect(() => {
    if (!grid && searchOpen && searchOpen !== prevSearchOpen.current) {
      setTimeout(() => {
        ref.current.focus();
        setOpen(true);
      }, 350);
    }
    prevSearchOpen.current = searchOpen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpen]);

  const onChangeValue = (e) => {
    var value = "";
    if (e) {
      value = e.target.value;
    }
    setValue(value);
    if (value.length > 0) {
      var regExp = new RegExp(value, "i");
      applications[4].results = applications[4].original.filter((res) =>
        regExp.test(`${res.title} ${res.keywords.join(" ")}`)
      );
      // if (applications[4].results.length === 0) {
      //   applications[4].results.push({ title: "keine Übereinstimmung" });
      // }
      applications[5].results = applications[5].original.filter((res) =>
        regExp.test(res.title)
      );
      // if (applications[5].results.length === 0) {
      //   applications[5].results.push({ title: "keine Übereinstimmung" });
      // }
      applications[1].results = [];
      applications[2].results = [];
      applications[3].results = [];

      setApplications(applications);
      if (value.length > 3) {
        getAdress(value);
        getFlurstueck(value);
        getBplan(value);
      }
    } else {
      setApplications(
        applications.map((a) => {
          if (a.original) a.results = a.original;
          return a;
        })
      );
    }
  };

  const getAdress = (value) => {
    setProgressAdress(true);
    axios
      .get(
        `https://geoportal.kreis-herford.de/bkg_geosearch?bbox=923665,6799838,1006675,6864656&outputformat=json&srsName=EPSG:3857&count=4&query=${value}`
      )
      .then((res) => {
        applications[1].results = res.data.features.map((res) => {
          var zoomLevel;
          switch (res.properties.typ) {
            case "Haus":
              zoomLevel = 19;
              break;
            case "Strasse":
              zoomLevel = 18;
              break;
            case "Ort":
              zoomLevel = 16;
              break;
            case "Geoname":
              zoomLevel = 14;
              break;
            default:
              zoomLevel = 18;
          }
          return {
            title: res.properties.text,
            link: `https://geoportal.kreis-herford.de/geoviewer/?layerIds=51,1,104&visibility=true,true,true&transparency=0,0,0&center=${res.geometry.coordinates[0]},${res.geometry.coordinates[1]}&marker=${res.geometry.coordinates[0]},${res.geometry.coordinates[1]}&projection=EPSG:3857&zoomLevel=${zoomLevel}`,
          };
        });
        // if (applications[1].results.length === 0) {
        //   applications[1].results.push({ title: "keine Übereinstimmung" });
        // }
        setApplications(applications);
        setProgressAdress(false);
      })
      .catch((err) => {
        applications[1].results.push({
          title: "Datenquelle zurzeit nicht erreichbar",
        });
        setProgressAdress(false);
        console.log(err);
      });
  };

  const getFlurstueck = (value) => {
    setProgressFlurstueck(true);
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/flurstueck/fsk.php?query=${value}`
      )
      .then((res) => {
        applications[2].results = res.data.map((res) => {
          return {
            title: res.flurstueck,
            link: `https://geoportal.kreis-herford.de/geoviewer/?layerIds=51,2,104&visibility=true,true,true&transparency=0,0,0&center=${res.ost},${res.nord}&marker=${res.ost},${res.nord}&projection=EPSG:4326&zoomLevel=18`,
          };
        });
        // if (applications[2].results.length === 0) {
        //   applications[2].results.push({ title: "keine Übereinstimmung" });
        // }
        setApplications(applications);
        setProgressFlurstueck(false);
      })
      .catch((err) => {
        applications[2].results.push({
          title: "Datenquelle zurzeit nicht erreichbar",
        });
        setApplications(applications);
        setProgressFlurstueck(false);
        console.log(err);
      });
  };

  const getBplan = (value) => {
    setProgressBplan(true);
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/bplan/bplan.php?suche=${value}&limit=4`
      )
      .then((res) => {
        applications[3].results = res.data.map((res) => {
          return {
            title: `${res.nummer} ${res.name} (${res.gemeinde})`,
            link: `https://geoportal.kreis-herford.de/geoviewer/?layerIds=51,1,104,201&visibility=true,true,true,true&transparency=0,0,0,0&center=${res.x},${res.y}&marker=${res.x},${res.y}&projection=EPSG:4326&zoomLevel=17`,
          };
        });
        // if (applications[3].results.length === 0) {
        //   applications[3].results.push({ title: "keine Übereinstimmung" });
        // }
        setApplications(applications);
        setProgressBplan(false);
      })
      .catch((err) => {
        applications[3].results.push({
          title: "Datenquelle zurzeit nicht erreichbar",
        });
        setApplications(applications);
        setProgressBplan(false);
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        width: {
          mobile: searchOpen ? "calc(100vw - 2 * 24px)" : "100%",
          logo: "100%",
        },
        justifyContent: "end",
        display: "flex",
      }}
    >
      <div
        style={{
          // margin: "0 10px",
          display: "flex",
        }}
      >
        {!grid && !searchOpen ? (
          <div id="suche" onClick={onOpen} style={{ cursor: "pointer" }}>
            <SearchIcon />
          </div>
        ) : null}
        <StyledInputBase
          inputRef={ref}
          autoFocus={searchOpen}
          id="sucheingabe"
          placeholder="Anwendung, Adresse, Flurstück, ..."
          inputProps={{
            style:
              grid || searchOpen
                ? {
                    width: "250px",
                    padding: "0 10px",
                    paddingLeft: "15px",
                    zIndex: 1,
                  }
                : {},
            value: value,
            onChange: onChangeValue,
          }}
          startAdornment={
            grid || searchOpen ? (
              <div
                id="suche"
                onClick={() => ref.current.focus()}
                style={{ cursor: "pointer" }}
              >
                <SearchIcon />
              </div>
            ) : null
          }
        />
        <Backdrop
          open={open}
          sx={{
            top: { grid: 100, accordion: 60 },
            background: "rgba(0, 0, 0, 0.3)",
          }}
        />
        <Backdrop
          open={open}
          onClick={onClose}
          sx={{ background: "transparent", zIndex: 0 }}
        />
        <Location />
        <Popper
          open={open}
          anchorEl={ref.current}
          placement="bottom-end"
          transition
          sx={{ zIndex: 9999999 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <List
                sx={{
                  width: `min(${ref.current.clientWidth + 38}px, ${
                    document.body.clientWidth - 20
                  }px)`,
                  borderRadius: 0,
                  marginTop: "10px",
                  bgcolor: "background.paper",
                  maxHeight: "min(600px, 70vh)",
                  overflow: "auto",
                  padding: 0,
                }}
              >
                {applications.map((theme, index) => {
                  const noApplicationLinks = index < applications.length - 2;
                  return noApplicationLinks || theme.results.length > 0 ? (
                    <List
                      key={`${index}-${
                        theme.dataLink && value.length > 0
                          ? `${theme.dataLink}${value}`
                          : theme.link
                      }`}
                      sx={{
                        padding: 0,
                        paddingBottom:
                          (applications[5].title === theme.title &&
                            applications[5].results.length > 0) ||
                          (applications[4].title === theme.title &&
                            applications[5].results.length === 0 &&
                            applications[4].results.length > 0)
                            ? "10px"
                            : 0,
                      }}
                      component="nav"
                      subheader={
                        <ListSubheader
                          component={noApplicationLinks ? Link : "div"}
                          sx={
                            noApplicationLinks
                              ? {
                                  fontWeight: "700",
                                  display: "block",
                                  textDecoration: "none",
                                  cursor: "pointer",
                                  paddingRight: "10px",
                                  "&:hover": {
                                    backgroundColor: "#f5f5f5", // (theme) =>   theme.palette.action.hover,
                                  },
                                }
                              : { fontWeight: "700" }
                          }
                          target={noApplicationLinks ? "_blank" : null}
                          href={
                            theme.dataLink && value.length > 0
                              ? `${theme.dataLink}${value}`
                              : theme.link
                          }
                          onClick={() => {
                            if (noApplicationLinks) {
                              trackEvent({
                                category: "Suche",
                                action: theme.log,
                                name: value,
                              });
                              if (theme.title.includes("Formular")) {
                                trackEvent({
                                  category: "Formular",
                                  action: "öffnen",
                                  name: theme.log,
                                });
                              }
                              trackSiteSearch({
                                keyword: value ? value : "<empty>",
                                category: theme.log,
                                count: searchCount(),
                              });
                            }
                            if (theme.title.includes("Formular")) {
                              dispatch(setComponent(theme.title.split(" ")[0]));
                              dispatch(toggle());
                            }
                            onClose(false);
                          }}
                        >
                          {(index === 1 && progressAdress) ||
                          (index === 2 && progressFlurstueck) ||
                          (index === 3 && progressBplan) ? (
                            <CircularProgress
                              size={20}
                              sx={{ marginRight: "5px" }}
                            />
                          ) : null}
                          {theme.title}
                        </ListSubheader>
                      }
                    >
                      {theme.results.map((res, i) => {
                        const noResults =
                          // res.title === "keine Übereinstimmung" ||
                          res.title === "Datenquelle zurzeit nicht erreichbar";
                        return (
                          <ListItem
                            key={`${i}-${res.link}`}
                            button={res.link}
                            disableGutters
                            secondaryAction={
                              !noResults ? (
                                index === 1 ? (
                                  <LocationIcon style={{ marginLeft: "5px" }} />
                                ) : index === 2 ? (
                                  <ParcelIcon style={{ marginLeft: "5px" }} />
                                ) : index === 3 ? (
                                  <MapIcon style={{ marginLeft: "5px" }} />
                                ) : index === 5 ? (
                                  <LinkIcon style={{ marginLeft: "5px" }} />
                                ) : (
                                  <NewTab style={{ marginLeft: "5px" }} />
                                )
                              ) : null
                            }
                            className={noResults ? "noResults" : ""}
                            sx={{
                              ".MuiListItemSecondaryAction-root": {
                                right: "10px",
                                backgroundColor: "transparent",
                              },
                              paddingLeft: "28px",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                              color: (theme) => theme.palette.primary.main,
                            }}
                            onClick={() => {
                              trackEvent({
                                category: "Suche",
                                action: theme.log,
                                name: value,
                              });
                              if (noResults) {
                                onChangeValue();
                                ref.current.focus();
                              } else {
                                trackSiteSearch({
                                  keyword: value ? value : "<empty>",
                                  category: `${res.title} (${theme.log})`,
                                  count: searchCount(),
                                });
                                onClose(false);
                              }
                            }}
                            component={res.link ? Link : null}
                            target={res.link ? "_blank" : null}
                            href={res.link}
                          >
                            {res.title}
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : null;
                })}
              </List>
            </Fade>
          )}
        </Popper>
      </div>
    </Box>
  );
};

export default Search;
