import { Box, Breadcrumbs as MuiBreadcrumbs } from "@mui/material";

import { ReactComponent as HomeIcon } from "../media/home_white_24dp.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const getTopics = (themes, indexArray) => {
  var topics = [];
  if (indexArray.length > 0) {
    indexArray.forEach((index, i) => {
      topics.push(
        i === 0 ? themes[index] : topics[topics.length - 1].topics[index]
      );
    });
  }
  return topics;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const themes = useSelector((state) => state.theme.themes);
  const activeSubject = useSelector((state) => state.theme.subject);

  const isImpressum = location.pathname.endsWith("/impressum");
  const isDatenschutz = location.pathname.endsWith("/datenschutz");
  const [subtopics, setSubtopics] = useState(getTopics(themes, activeSubject));

  useEffect(() => {
    setSubtopics(getTopics(themes, activeSubject));
  }, [activeSubject, themes]);

  return isImpressum || isDatenschutz || activeSubject.length > 0 ? (
    <Box
      sx={{
        height: "30.5px",
        marginBottom: "20px",
        zIndex: 3,
        position: "relative",
        bottom: "24px",
      }}
    >
      <MuiBreadcrumbs
        separator="›"
        sx={{
          paddingBottom: "20px",
          paddingTop: "24px",
          position: "fixed",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Box
          onClick={() => navigate("/")}
          sx={{
            fill: "black",
            cursor: "pointer",
            "&:hover": {
              fill: (theme) => theme.palette.primary.light,
            },
          }}
        >
          <HomeIcon />
        </Box>
        {isImpressum ? (
          <div>Impressum</div>
        ) : isDatenschutz ? (
          <div>Datenschutz</div>
        ) : (
          subtopics.map((topic, i) => {
            return (
              <Box
                key={topic.hashtags[0]}
                onClick={() =>
                  i === activeSubject.length - 1
                    ? {}
                    : navigate(`#${topic.hashtags[0]}`)
                }
                sx={{
                  color: i === activeSubject.length - 1 ? "inherit" : "black",
                  cursor:
                    i === activeSubject.length - 1 ? "default" : "pointer",
                  "&:hover":
                    i === activeSubject.length - 1
                      ? {}
                      : {
                          color: (theme) => theme.palette.primary.light,
                          textDecoration: "underline",
                        },
                }}
              >
                {topic.name}
              </Box>
            );
          })
        )}
      </MuiBreadcrumbs>
    </Box>
  ) : (
    <Box sx={{ marginTop: "24px" }} />
  );
};

export default Breadcrumbs;
