import { Box } from "@mui/material";
import React from "react";

import { Outlet } from "react-router-dom";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Breadcrumbs from "./Breadcrumbs";
import Cookies from "./Cookies";
import Alert from "./Alert";

const Layout = () => {
  return (
    <Box className="wrapper">
      <Alert />
      <Cookies />
      <Navbar />
      <Box sx={{ margin: 3 }}>
        <Breadcrumbs />
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
