import React, { useState } from "react";

import { Dialog, DialogContent } from "@mui/material";

import DialogTitle from "./DialogTitle";
import moment from "moment";

const isIE = navigator.userAgent.indexOf("Trident") > -1;
const ieMessage = (
  <div>
    &#9888; Ihr Browser "Internet Explorer" wird nicht mehr unterstützt. Bitte
    aktualisieren Sie Ihren Browser für ein optimales Erlebnis.
  </div>
);
const alertId = "afd30c04-e622-49df-9315-3131bf014e61";
const alertMessage = null;
// const alertMessage = (
//   <div>
//     Wegen Wartungsarbeiten stehen einige Anwendungen ab dem 24.07.2024 bis zum
//     26.07.2024 nicht zur Verfügung
//     {/* Willkommen im <b>neuen</b> Geoportal des Kreises Herford. */}
//   </div>
// );
const alertDisplayed = alertMessage && !localStorage.getItem(alertId);

const Alert = () => {
  const [open, setOpen] = useState(isIE || alertDisplayed);

  const toggle = () => {
    setOpen(!open);
    if (alertDisplayed) {
      localStorage.setItem(alertId, moment().format("DD.MM.YYYY HH:mm:ss"));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth={"tablet"}
      fullWidth={true}
      PaperProps={{ sx: { borderRadius: 0 } }}
    >
      <DialogTitle onClose={toggle} style={{ height: "26px" }}>
        <img
          height="26px"
          alt=""
          src="https://geoportal.kreis-herford.de/medien/apps/schriftzug_geoportal_blau.png"
        />
      </DialogTitle>
      <DialogContent sx={{ marginTop: "0px" }}>
        {alertDisplayed ? (
          <>
            {alertMessage}
            {isIE ? (
              <div
                style={{
                  borderTop: "1px solid #00568E",
                  margin: "16px 0",
                }}
              />
            ) : null}
          </>
        ) : null}
        {isIE ? ieMessage : null}
      </DialogContent>
    </Dialog>
  );
};

export default Alert;
