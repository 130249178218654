import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggle } from "../actions/searchActions";

import axios from "axios";

import { ReactComponent as NewTab } from "../media/open_in_new_white_18dp.svg";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

class AdressSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ort: "",
      loadingStrassen: false,
      strassen: [],
      strasse: "",
      loadingHausnummern: false,
      hausnummer: { hausnummer: "" },
      hausnummern: [],
      error: false,
    };
    this.ortRef = React.createRef();
    this.strasseRef = React.createRef();
    this.hausnummerRef = React.createRef();
  }

  componentDidMount() {
    this.ortRef.current.focus();
  }

  getStrassen = (ort) => {
    this.setState({ loadingStrassen: true });
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/adresse/strasse.php?ort=${ort}`
      )
      .then((res) => {
        var strassen = res.data.map((str) => str.strasse);
        this.setState({ strassen: strassen, loadingStrassen: false });
        this.strasseRef.current.focus();
      })
      .catch((err) => {
        this.setState({ error: true, loadingStrassen: false });
      });
  };

  getHausnummer = (strasse, ort) => {
    this.setState({ loadingHausnummern: true });
    axios
      .get(
        `https://geoportal.kreis-herford.de/skripte/suche/adresse/hausnummer.php?strasse=${strasse}&ort=${ort}`
      )
      .then((res) => {
        this.setState({ hausnummern: res.data, loadingHausnummern: false });
        this.hausnummerRef.current.focus();
      });
  };

  render() {
    return (
      <div>
        <div
          style={{
            color: "red",
            marginBottom: "20px",
            maxWidth: 300,
            width: "100%",
          }}
        >
          {this.state.error
            ? "Es ist zu einem Fehler bei der Adresssuche gekommen. Versuchen Sie es bitte zu einem späteren Zeitpunkt erneut."
            : ""}
        </div>
        <Autocomplete
          disabled={this.state.error}
          sx={{
            maxWidth: 300,
            width: "100%",
            marginBottom: "10px",
          }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          noOptionsText={"keine Übereinstimmung"}
          getOptionLabel={(option) => option}
          options={[
            "Bünde",
            "Enger",
            "Herford",
            "Hiddenhausen",
            "Kirchlengern",
            "Löhne",
            "Rödinghausen",
            "Spenge",
            "Vlotho",
          ]}
          value={this.state.ort}
          onChange={(e, val) => {
            if (val) {
              this.setState({ ort: val }, () =>
                this.getStrassen(this.state.ort)
              );
            } else {
              this.setState({
                ort: "",
                strasse: "",
                strassen: [],
                hausnummer: { hausnummer: "" },
                hausnummern: [],
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={this.ortRef}
              label="Gemeinde"
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
              }}
            />
          )}
        />

        <Autocomplete
          disabled={this.state.ort === "" || this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          getOptionLabel={(option) => option}
          options={this.state.strassen}
          noOptionsText={"keine Übereinstimmung"}
          groupBy={(option) => option[0]}
          loading={this.state.loadingStrassen}
          value={this.state.strasse}
          onChange={(e, val) => {
            if (val) {
              this.setState({ strasse: val }, () =>
                this.getHausnummer(this.state.strasse, this.state.ort)
              );
            } else {
              this.setState({
                strasse: "",
                hausnummer: { hausnummer: "" },
                hausnummern: [],
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Straße"
              inputRef={this.strasseRef}
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loadingStrassen ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        <Autocomplete
          disabled={this.state.strasse === "" || this.state.error}
          sx={{ maxWidth: 300, width: "100%", marginBottom: "10px" }}
          componentsProps={{
            paper: {
              sx: { borderRadius: "2px" },
            },
          }}
          getOptionLabel={(option) => {
            if (option.zusatz) {
              return option.hausnummer + option.zusatz;
            } else {
              return option.hausnummer;
            }
          }}
          options={this.state.hausnummern}
          noOptionsText={"keine Übereinstimmung"}
          loading={this.state.loadingHausnummern}
          value={this.state.hausnummer}
          onChange={(e, val) => {
            if (val) {
              this.setState({ hausnummer: val });
            } else {
              this.setState({
                hausnummer: { hausnummer: "" },
              });
            }
          }}
          openOnFocus
          renderInput={(params) => (
            <TextField
              {...params}
              label="Hausnummer"
              inputRef={this.hausnummerRef}
              InputProps={{
                sx: { borderRadius: "2px" },
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {this.state.loadingHausnummern ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Button
          sx={{ borderRadius: "2px" }}
          fullWidth
          variant="contained"
          disabled={!this.state.hausnummer.east}
          onClick={() => {
            this.props.toggle();
            // if (window.tracking) {
            //   console.log(
            //     "matomo",
            //     "Startseite",
            //     "Adresse",
            //     `${this.state.strasse} ${`${this.state.hausnummer.hausnummer}${
            //       this.state.hausnummer.zusatz
            //         ? this.state.hausnummer.zusatz
            //         : ""
            //     }`}, ${this.state.ort}`
            //   );
            // }
          }}
          component={Link}
          href={`https://geoportal.kreis-herford.de/geoviewer/?layerIds=51,1,104&visibility=true,true,true&transparency=0,0,0&center=${this.state.hausnummer.east},${this.state.hausnummer.north}&marker=${this.state.hausnummer.east},${this.state.hausnummer.north}&projection=EPSG:4326&zoomLevel=18`}
          target="_blank"
        >
          <NewTab style={{ marginRight: "5px" }} />
          Adresse in Karte anzeigen
        </Button>
      </div>
    );
  }
}

AdressSearch.propTypes = {
  toggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  toggle,
})(AdressSearch);
