import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setSubject, findSubject } from "../actions/themeActions";

import { useLocation } from "react-router-dom";

import Accordion from "./Accordion";
import ThemesGrid from "./ThemesGrid";
import SubtopicsGrid from "./SubtopicsGrid";

import { Box, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const Home = () => {
  const dispatch = useDispatch();
  const activeSubject = useSelector((state) => state.theme.subject);
  const progress = useSelector((state) => state.theme.progress);
  const theme = useTheme();

  const grid = useMediaQuery(theme.breakpoints.up("grid"));

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      dispatch(findSubject(location.hash));
    } else {
      dispatch(setSubject());
    }
  }, [location.hash, dispatch]);

  return !progress ? (
    activeSubject.length > 0 ? (
      <SubtopicsGrid />
    ) : grid ? (
      <>
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            variant="h3"
            sx={{ marginBottom: "10px", fontSize: "40px" }}
          >
            Willkommen im Geoportal des Kreises Herford
          </Typography>
          <Typography variant="body1">
            Durch das Geoportal des Kreises Herford stellen wir Ihnen frei
            zugänglich und kostenfrei Karten zu einer Vielzahl verschiedener
            Themenbereiche zur Verfügung.
          </Typography>
        </Box>
        <ThemesGrid />
      </>
    ) : (
      <Accordion />
    )
  ) : null;
};

export default Home;
