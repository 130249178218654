import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";

import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter as Router } from "react-router-dom";

const instance = createInstance({
  urlBase: "https://piwik.krz.de/",
  siteId: 95,
  // userId: "UID76903202", // optional, default value: `undefined`.
  // trackerUrl: "https://LINK.TO.DOMAIN/tracking.php", // optional, default value: `${urlBase}matomo.php`
  // srcUrl: "https://LINK.TO.DOMAIN/tracking.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    // disableCookies: true,
    // setSecureCookie: true,
    // setRequestMethod: "POST",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <Router
        basename={
          window.location.pathname.includes("/startseite") ? "/startseite" : "/"
        }
      >
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("delucse")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
