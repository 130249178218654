import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useInViewport } from "../hooks/useInViewport";
import DialogTitle from "./DialogTitle";

import Grid from "@mui/material/Grid";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { ReactComponent as InfoIcon } from "../media/info_white_24dp.svg";
import { ReactComponent as NewTab } from "../media/open_in_new_white_48dp.svg";
import { ReactComponent as NewTabSmall } from "../media/open_in_new_white_18dp.svg";
import { ReactComponent as FurtherInformationIcon } from "../media/topic_white_24dp.svg";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Skeleton } from "@mui/material";

const Banner = ({ topic, small }) => {
  return topic.length > 0 ? (
    <Box
      sx={{
        position: "absolute",
        // top: "10px",
        top: small ? "35px" : "25px",
        // right: "-55px",
        right: small ? "-40px" : "-60px",
        backgroundColor: (theme) =>
          topic[0].update
            ? theme.palette.primary.light
            : theme.palette.primary.main,
        fontFamily: " Arial, Helvetica, sans-serif",
        color: "white",
        transform: "rotate(40deg)",
        msTransform: "rotate(40deg)",
        // transform: "rotate(45deg)",
        // msTransform: "rotate(45deg)",
        textAlign: "center",
        padding: small ? "5px" : "15px",
        // width: "150px",
        width: "180px",
        zIndex: 1,
        fontWeight: 600,
        fontSize: "14px",
      }}
    >
      {topic[0].update ? "UPDATE" : "NEU"}
    </Box>
  ) : null;
};

const Subtopic = ({ topic }) => {
  const { trackEvent } = useMatomo();
  const themes = useSelector((state) => state.theme.themes);

  const [open, setOpen] = useState(false);
  const [fullDescription, setFullDescription] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const newTopic = themes[0].topics.filter(
    (t) =>
      t.name === topic.name &&
      t.link === topic.link &&
      t.image === topic.image &&
      t.date === topic.date &&
      t.update === topic.update
  );

  const elemRef = useRef();
  const inViewport = useInViewport(elemRef);
  const [loading, setLoading] = useState(true);

  const barRef = useRef();
  const [barHeight, setBarHeight] = useState(0);

  const setHeight = () => {
    if (topic.logo && barRef.current) setBarHeight(barRef.current.clientHeight);
  };

  useEffect(() => {
    setHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barRef.current]);

  useEffect(() => {
    window.addEventListener("resize", setHeight);
    return () => window.removeEventListener("resize", setHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addHyperLink = (text, ready = []) => {
    var isHyperlink = text.includes("https://");
    if (isHyperlink) {
      const linkStart = isHyperlink ? text.indexOf("https://") : null;
      const linkEnd = text.indexOf(" ", linkStart);
      const firstTextSection = text.slice(0, linkStart);
      const linkSection = text.slice(linkStart, linkEnd);
      const secondSection = text.slice(linkEnd);
      ready = [
        ...ready,
        addMail(firstTextSection),
        <a
          href={linkSection}
          target="_blank"
          rel="noreferrer"
          className="appLink"
          style={{ wordBreak: "break-all" }}
        >
          {linkSection}
        </a>,
      ];
      return addHyperLink(secondSection, ready);
    } else {
      text = addMail(text);
      return [...ready, ...text];
    }
  };

  const addMail = (text, ready = []) => {
    var mail = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/);
    if (mail?.length > 0) {
      const mailStart = text.indexOf(mail[0]);
      const mailEnd = text.indexOf(" ", mailStart);
      const firstTextSection = text.slice(0, mailStart);
      const mailSection = text.slice(mailStart, mailEnd);
      const secondSection = text.slice(mailEnd);
      ready = [
        ...ready,
        firstTextSection,
        <a
          href={`mailto:${mailSection}`}
          target="_blank"
          rel="noreferrer"
          className="appLink"
        >
          {mailSection}
        </a>,
      ];
      return addMail(secondSection, ready);
    } else {
      return [...ready, text];
    }
  };

  const addLinks = (text) => {
    return addHyperLink(text).map((elem, index) => (
      <React.Fragment key={index}>{elem}</React.Fragment>
    ));
  };

  return (
    <Grid item mobile={12} tablet={6} desktop={4} screen={3}>
      <ImageListItem
        sx={{
          overflow: "hidden",
          height: "200px !important",
          width: "100%",
        }}
      >
        <a
          style={{ textDecoration: "none" }}
          href={topic.link}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            trackEvent({
              category: "Anwendung",
              action: "öffnen",
              name: topic.name, // optional
            });
          }}
        >
          <Banner topic={newTopic} />
          <div
            className={`imageZoom${topic.logo ? "Logo" : ""}`}
            style={{
              position: "relative",
              height: topic.logo ? `calc(100% - ${barHeight}px - 1px)` : "100%",
              width: topic.logo ? "calc(100% - 2 * 1px)" : "100%",
              border: topic.logo ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
              borderBottom: "0px",
            }}
          >
            <NewTab
              className="openNewTab"
              style={{
                color: "red",
                zIndex: 1,
                position: "absolute",
                width: "100%",
                top: "50%",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)",
              }}
            />
            <figure
              ref={elemRef}
              style={{
                display: "inline-block",
                overflow: "hidden",
                height: "100%",
                width: "100%",
                margin: 0,
                position: "absolute",
              }}
            >
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width={"100%"}
                  height={"100%"}
                />
              ) : null}
              {inViewport ? (
                <img
                  src={topic.image}
                  alt={""}
                  onLoad={() => setLoading(false)}
                  style={{
                    objectFit: topic.logo ? "contain" : "cover",
                    height: "100%",
                    width: "100%",
                  }}
                />
              ) : null}
            </figure>
          </div>
        </a>
        <ImageListItemBar
          position="bottom"
          ref={barRef}
          sx={{ zIndex: 2 }}
          title={
            <div
              style={{
                fontWeight: 600,
                fontSize: "20px",
                whiteSpace: "initial",
              }}
            >
              {topic.name}
            </div>
          }
          actionIcon={
            topic.description ? (
              <IconButton
                onClick={(event) => {
                  toggle();
                  event.preventDefault();
                  trackEvent({
                    category: "Anwendung",
                    action: "Info öffnen",
                    name: topic.name, // optional
                  });
                }}
              >
                <InfoIcon />
              </IconButton>
            ) : null
          }
        />
      </ImageListItem>
      {topic.description ? (
        <Dialog
          PaperProps={{ sx: { borderRadius: 0 } }}
          open={open}
          onClose={toggle}
          maxWidth="tablet"
          fullWidth
        >
          <DialogTitle onClose={toggle}>{topic.name}</DialogTitle>
          <DialogContent>
            <Grid container space={0}>
              <Grid
                container
                mobile={12}
                tablet={4}
                sx={{
                  borderRight: {
                    tablet: "thin solid rgba(0, 0, 0, 0.12)",
                  },
                  paddingRight: {
                    tablet: "10px",
                  },
                  display: "inline",
                }}
              >
                <Grid
                  item
                  mobile={12}
                  sx={{
                    borderBottom: {
                      mobile: "thin solid rgba(0, 0, 0, 0.12)",
                    },
                    paddingBottom: { mobile: "10px" },
                  }}
                >
                  <div style={{ overflow: "hidden", position: "relative" }}>
                    {/* <Banner topic={newTopic} small /> */}
                    <Box
                      component={"img"}
                      src={topic.image}
                      alt={topic.name}
                      loading="lazy"
                      sx={{
                        objectFit: topic.logo ? "contain" : "cover",
                        maxWidth: {
                          mobile: "unset",
                          tablet: topic.logo ? "100%" : "unset",
                        },
                        minWidth: { mobile: "100%", tablet: "unset" },
                        left: "50%",
                        position: "relative",
                        transform: "translateX(-50%)",
                        height: "100%",
                        maxHeight: "150px",
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  mobile={12}
                  sx={{
                    borderBottom: {
                      mobile: "thin solid rgba(0, 0, 0, 0.12)",
                      tablet: "0px",
                    },
                    paddingBottom: { mobile: "10px", tablet: 0 },
                  }}
                >
                  <a
                    href={`https://geoportal.kreis-herford.de/smartfinder-sdi/?lang=de#/datasets/iso/${topic.id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#000000",
                      display: "flex",
                      paddingTop: "10px",
                    }}
                    onClick={() => {
                      trackEvent({
                        category: "Anwendung",
                        action: "Details öffnen",
                        name: topic.name, // optional
                      });
                    }}
                  >
                    <FurtherInformationIcon />
                    <div
                      style={{
                        marginLeft: "4px",
                        lineHeight: "24px",
                        height: "24px",
                      }}
                    >
                      weitere Details
                    </div>
                  </a>
                </Grid>
              </Grid>
              <Grid
                mobile={12}
                tablet={8}
                sx={{
                  paddingLeft: { tablet: "10px" },
                  paddingTop: { mobile: "10px", tablet: 0 },
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginBottom: "56.5px",
                    overflow: "auto",
                    maxHeight: "40vh",
                    textAlign: "justify",
                  }}
                >
                  {topic.description.length > 500 ? (
                    <div
                      style={{
                        marginRight: fullDescription ? "10px" : "0px",
                      }}
                    >
                      {!fullDescription
                        ? addLinks(
                            topic.description.substring(
                              0,
                              topic.description
                                .substring(0, 500)
                                .lastIndexOf(" ")
                            ) + " ..."
                          )
                        : addLinks(topic.description)}{" "}
                      <text
                        onClick={() => setFullDescription(!fullDescription)}
                        style={{
                          float: "right",
                          cursor: "pointer",
                        }}
                        className="appLink"
                      >
                        {!fullDescription
                          ? "mehr anzeigen"
                          : "Weniger anzeigen"}
                      </text>
                    </div>
                  ) : (
                    addLinks(topic.description)
                  )}
                </div>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: { tablet: "calc(100% - 10px)", mobile: "100%" },
                    paddingTop: "20px",
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      toggle();
                      trackEvent({
                        category: "Anwendung",
                        action: "über Info öffnen",
                        name: topic.name, // optional
                      });
                    }}
                    component={Link}
                    href={topic.link}
                    target="_blank"
                    sx={{ borderRadius: "2px" }}
                  >
                    <NewTabSmall style={{ marginRight: "5px" }} />
                    Anwendung öffnen
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </Grid>
  );
};

export default Subtopic;
