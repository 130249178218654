import { CHANGE_SUBJECT } from "./types";

import indexes from "../data/indexes.json";

export const setSubject = (value) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_SUBJECT,
    payload: value,
  });
};

export const findSubject = (hash) => (dispatch) => {
  dispatch(setSubject(indexes[hash.slice(1)]));
};
