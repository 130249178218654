import React, { useEffect } from "react";

import { Route, Routes } from "react-router-dom";

import DocumentTitle from "react-document-title";

import Home from "./components/Home";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Layout from "./components/Layout";
import useScrollToTop from "./hooks/useScrollTop";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00568E",
    },
  },
  breakpoints: {
    values: {
      accordion: 0,
      grid: 900,

      mobile: 0,
      logo: 500,
      tablet: 600,
      desktop: 900,
      screen: 1200,
    },
  },
});

const App = () => {
  const { pushInstruction, enableLinkTracking } = useMatomo();

  useEffect(() => {
    pushInstruction("requireConsent");
    if (window.tracking) {
      pushInstruction("setConsentGiven");
    } else {
      pushInstruction("forgetConsentGiven");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  enableLinkTracking();

  useScrollToTop();

  const isIE =
    navigator.userAgent.indexOf("MSIE") > -1 ||
    navigator.userAgent.indexOf("Trident") > -1;

  return isIE ? (
    <div
      style={{
        height: "calc(100vh - 2 * 20px)",
        width: "calc(100% - 2 * 20px)",
        alignItems: "center",
        display: "grid",
        margin: "20px",
      }}
    >
      <div style={{ margin: "0 20%" }}>
        <img
          width="100%"
          alt=""
          src="https://geoportal.kreis-herford.de/medien/apps/logo_geoportal.svg"
        />
        <div
          style={{
            border: "1px solid #00568E",
            padding: "10px",
            marginTop: "40px",
            textAlign: "center",
          }}
        >
          &#9888; Ihr Browser "Internet Explorer" wird nicht mehr unterstützt.
          Bitte aktualisieren Sie Ihren Browser.
        </div>
      </div>
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path={"/"} element={<Layout />}>
          <Route
            index
            element={
              <DocumentTitle title="GEOPortal Kreis Herford">
                <Home />
              </DocumentTitle>
            }
          />
          <Route
            path={"/impressum"}
            element={
              <DocumentTitle title="Impressum | GEOPortal Kreis Herford">
                <Impressum />
              </DocumentTitle>
            }
          />
          <Route
            path={"/datenschutz"}
            element={
              <DocumentTitle title="Datenschutz | GEOPortal Kreis Herford">
                <Datenschutz />
              </DocumentTitle>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
