import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setSubject } from "../actions/themeActions";

import Search from "./Search";

import { useNavigate } from "react-router-dom";

// import Help from "./Help";

import { ReactComponent as GeoportalLogo } from "../media/logo_geoportal_w.svg";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/material";

const Navbar = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <AppBar
        id="navbar"
        sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
        position="fixed"
      >
        <Toolbar
          sx={{
            minHeight: { accordion: "60px", grid: "100px" },
            margin: "0 24px",
            padding: 0,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: 0,
              cursor: "pointer",
              height: {
                accordion: "calc(100% - 2 * 9px)",
                grid: "calc(100% - 2 * 15px)",
              },
              width: "260px",
              margin: "9px 0",
            }}
            onClick={() => {
              navigate("/");
              document.documentElement.scrollTop = 0;
            }}
          >
            <GeoportalLogo />
          </Box>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              display: "flex",
              backgroundColor: (theme) => theme.palette.primary.main,
              margin: { accordion: "11px 0", grid: "31px 0" },
            }}
          >
            <div>
              <Search />
            </div>
            {/* <div style={{ margin: "auto 0px auto 10px" }}>
              <Help />
            </div> */}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar
        sx={{
          minHeight: { accordion: "60px", grid: "100px" },
          // borderBottom: "1px solid white",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
    </>
  );
};

Navbar.propTypes = {
  setSubject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setSubject })(Navbar);
