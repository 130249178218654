import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useMatomo } from "@jonkoops/matomo-tracker-react";

const useScrollToTop = () => {
  const { pathname, hash } = useLocation();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hash]);
};

export default useScrollToTop;
