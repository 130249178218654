import React, { useRef } from "react";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useInViewport } from "../hooks/useInViewport";
import Aktuelles from "./Aktuelles";

import Box from "@mui/material/Box";

const Accordion = ({ theme, index, length }) => {
  const navigate = useNavigate();

  const elemRef = useRef();
  const inViewport = useInViewport(elemRef);

  return theme.topics.length > 0 ? (
    <div>
      <div
        ref={elemRef}
        style={{
          backgroundImage:
            theme.name === "Aktuelles"
              ? "linear-gradient(to left, rgba(255,255,255,0) 15%, rgba(255,255,255,1) 70%)"
              : `linear-gradient(to left, rgba(255, 255, 255, 0) 15%, rgb(255, 255, 255) 70%), url(${
                  inViewport
                    ? theme.mobileImage
                      ? theme.mobileImage
                      : theme.image
                    : ""
                })`,
          backgroundSize: "cover",
          height: "20vw",
          alignItems: "center",
          display: "flex",
          maxHeight: "150px",
          color: "#003d66", //"white",
          textShadow:
            "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
          fontSize: "min(8vw, 90px)", // (siehe .maxFontSizeIE),
          fontWeight: 400,
          cursor: "pointer",
          padding: "0 21px",
          position: "relative",
        }}
        className="maxFontSizeIE"
        onClick={() => navigate(`#${theme.hashtags[0]}`)}
      >
        {theme.name}
        {inViewport ? (
          theme.name === "Aktuelles" ? (
            <Aktuelles
              mobile
              src={theme.mobileImage ? theme.mobileImage : theme.image}
              style={{
                maxHeight: "100%",
                minWidth: "calc(100% + 2 * 21px)",
                marginLeft: "-16px",
                zIndex: -1,
                textShadow: "none",
                position: "absolute",
              }}
            />
          ) : null
        ) : null}
      </div>

      {length - 1 !== index ? (
        <Box
          sx={{
            height: {
              mobile: "5px",
              tablet: "10px",
            },
          }}
          style={{
            background: `linear-gradient(to left, white 40%, lightgrey)`,
          }}
        ></Box>
      ) : null}
    </div>
  ) : null;
};

const Accordions = () => {
  const themes = useSelector((state) => state.theme.themes);
  return (
    <div style={{ margin: "-24px" }}>
      {themes.map((theme, index) => (
        <Accordion
          theme={theme}
          index={index}
          key={`accordion-${index}`}
          length={themes.length}
        />
      ))}
    </div>
  );
};

export default Accordions;
